import * as React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import SideBar from "./SideBar/SideBar";

const Layout = () => {
  const [isContentVisible, setContentVisible] = React.useState(false);

  const toggleContent = () => {
    setContentVisible(!isContentVisible);
  };

  const handleHide = () => {
    setContentVisible(false);
  }

  return (
    <div>
      <SideBar
        isContentVisible={isContentVisible}
        toggleContent={toggleContent}
        handleHide={handleHide}
      />
      <section id="content">
        <Header />
        <main>
          <Outlet />
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default Layout;
