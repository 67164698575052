/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../../utils/authToken";
import { capitalizeFirstLetter } from "../../../utils/stringMethode";
import https from "../../../utils/https";
import { Button } from "reactstrap";
import ContextMenu from "../../Common/ContextMenu/ContextMenu";
import { removeAuthToken } from "../../../utils/authToken";
const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [user, setUser] = useState({});
  const userToken = getAuthToken();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const nagivate = useNavigate()
  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const handleClick = () => {
    setIsActive(!isActive);
  };

  const getUserInfo = () => {
    if (userToken) {
      https
        .get(`/admin/getID/${userToken.id}`, {
          params: {
            id_auth: userToken.id,
          },
        })
        .then((res) => {
          if (res && res.data) {
            setUser(res.data[0]);
          }
        });
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
    if (isActive) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    return () => {
      document.body.classList.remove("dark");
    };
  }, [isActive]);

  const handleLogout = (e) => {
    removeAuthToken();
    nagivate('/')
  };
  return (
    <nav>
      <input type="checkbox" id="switch-mode" hidden />
      <label
        htmlFor="switch-mode"
        className="switch-mode ml-auto"
        onClick={handleClick}
      >
        {" "}
        <i className="bx bxs-moon"></i>
      </label>

      <a className="notification">
        {user.username && (
          <p className="label-check">{capitalizeFirstLetter(user.username)}</p>
        )}
      </a>
      <Button className="profile" id="user-profil-info" type="button">
        <img src="/img/user-no-image.png" alt="Profil" />
      </Button>
      <ContextMenu
        isOpen={popoverOpen}
        togglePopover={togglePopover}
        target="user-profil-info"
      >
        <div>
          <button onClick={handleLogout} type="button" className="over_d">
            <i className="bi bi-box-arrow-in-right"></i>
            <span>Déconnexion</span>
          </button>
        </div>
      </ContextMenu>
    </nav>
  );
};

export default Header;
