import { getAuthToken } from "./authToken";

export const getPermission = (key, search) => {
  if (key) {
    const auth = getAuthToken();
    if (auth) {
      const permissions = auth.permissions;
      if (permissions && permissions.length) {
        if (!search) {
          if (typeof key === "string") {
            return permissions.includes(key);
          } else {
            let isInclude = false;
            for (let index = 0; index < key.length; index++) {
              const element = key[index];
              if (permissions.includes(element)) {
                isInclude = true;
                break;
              }
            }
            return isInclude;
          }
        } else {
          const string = permissions.join(", ");
          return string.includes(key);
        }
      } else {
        return false;
      }
    }else {
      return false;
    }
  }
};

export const havePermissions = (permissions=[],keyItems = []) => {
  let tmp = false;
  for (let index = 0; index < keyItems.length; index++) {
    const element = keyItems[index];
    if (permissions.includes(element)) {
      tmp = true;
      break;
    }
  }
  return tmp;
};