import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  indexArticle: 0,
  indexBelly: 0,
  indexUltraSound: 0,
  indexAppointement: 0,
  indexAds: 0,
  indexCategoryCourse: 0,
  indexCity: 0,
  indexCountry: 0,
  indexCourse: 0,
  indexFaq: 0,
  indexFruit: 0,
  indexAdress: 0,
  indexGuide: 0,
  indexName: 0,
  indexQuestion: 0,
  indexTask: 0,
  indexCustomer: 0,
  indexVaccine: 0
}

export const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setIndexArticle: (state, action) => {
      state.indexArticle = action.payload
    },
    setIndexBelly: (state, action) => {
      state.indexBelly = action.payload
    },
    setIndexUltraSound: (state, action) => {
      state.indexUltraSound = action.payload
    },
    setIndexAppointement: (state, action) => {
      state.indexAppointement = action.payload
    },
    setIndexAds: (state, action) => {
      state.indexAds = action.payload
    },
    setIndexCategoryCourse: (state, action) => {
      state.indexCategoryCourse = action.payload
    },
    setIndexCity: (state, action) => {
      state.indexCity = action.payload
    },
    setIndexCountry: (state, action) => {
      state.indexCountry = action.payload
    },
    setIndexCourse: (state, action) => {
      state.indexCourse = action.payload
    },
    setIndexFaq: (state, action) => {
      state.indexFaq = action.payload
    },
    setIndexFruit: (state, action) => {
      state.indexFruit = action.payload
    },
    setIndexAdress: (state, action) => {
      state.indexAdress = action.payload
    },
    setIndexGuide: (state, action) => {
      state.indexGuide = action.payload
    },
    setIndexName: (state, action) => {
      state.indexName = action.payload
    },
    setIndexQuestion: (state, action) => {
      state.indexQuestion = action.payload
    },
    setIndexTask: (state, action) => {
      state.indexTask = action.payload
    },
    setIndexCustomer: (state, action) => {
      state.indexCustomer = action.payload
    },
    setIndexVaccine: (state, action) => {
      state.indexVaccine = action.payload
    }
  },
})

export const {
  setIndexArticle,
  setIndexBelly,
  setIndexUltraSound,
  setIndexAppointement,
  setIndexAds,
  setIndexCategoryCourse,
  setIndexCity,
  setIndexCountry,
  setIndexCourse,
  setIndexFaq,
  setIndexFruit,
  setIndexAdress,
  setIndexGuide,
  setIndexName,
  setIndexQuestion,
  setIndexTask,
  setIndexCustomer,
  setIndexVaccine
} = paginationSlice.actions

export default paginationSlice.reducer