import * as React from 'react';
import { withErrorBoundary, useErrorBoundary } from "react-use-error-boundary";
import ErrorPage from '../ErrorPage/ErrorPage';



const ErrorBoundary = withErrorBoundary(({ children, isPrintError }) => {
    // eslint-disable-next-line no-unused-vars
    const [error, resetError] = useErrorBoundary(
        (error, errorInfo) => {}
    );

    if (error) {
        if (isPrintError) {
            return (<ErrorPage/>)
        } else {
            return null
        }

    } else {
        return <React.Fragment>{children}</React.Fragment>
    }
})


export default ErrorBoundary


