import * as React from 'react';

const GoBack = () => {
    const handleGoBack = () => {
        window.history.back();
      };
    return (
        <>
            <button type='button' className='btn-secondary' onClick={handleGoBack}>Retour</button>
        </>
    );
}

export default GoBack