import * as React from 'react';

const Loading = () => {
    return (
        <>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span className="sr-only">Chargement...</span>
        </>
    );
}

export default Loading 