/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Item from "../Item/Item";
import { menuItems } from "./data";
import { getPermission } from "../../../../utils/permissions";

const SubItem = ({ url, iconClass, text, onClick, search, keyItem }) => {
  if (!getPermission(keyItem, search)) return null;
  return (
    <li className="item" onClick={onClick}>
      <Item url={url}>
        <i className={iconClass}></i>
        <span className="text">{text}</span>
      </Item>
    </li>
  );
};

const SubMenu = ({
  url,
  iconClass,
  text,
  subItems,
  onItemClick,
  toggleContent,
  search,
  keyItem,
}) => {
  if (!getPermission(keyItem, search)) return null;
  return (
    <li className="groupeItem">
      <a onClick={toggleContent}>
        <Item url={url}>
          <i className={iconClass}></i>
          <span className="text">{text}</span>
        </Item>
      </a>
      <ul>
        {subItems.map((subItem, index) => (
          <SubItem
            key={index}
            url={subItem.url}
            iconClass={subItem.iconClass}
            text={subItem.text}
            onClick={onItemClick}
            search={subItem.search}
            keyItem={subItem.keyItem}
          />
        ))}
      </ul>
    </li>
  );
};

const ItemLink = ({ toggleContent }) => (
  <ul className="side-menu top">
    {menuItems.map((menuItem, index) =>
      menuItem.subItems ? (
        <SubMenu
          key={index}
          url={menuItem.url}
          iconClass={menuItem.iconClass}
          text={menuItem.text}
          subItems={menuItem.subItems}
          onItemClick={toggleContent}
          keyItem={menuItem.keyItem}
          search={menuItem.search}
          toggleContent={toggleContent}
        />
      ) : (
        <li className="item" key={index} onClick={toggleContent}>
          <Item url={menuItem.url}>
            <i className={menuItem.iconClass}></i>
            <span className="text">{menuItem.text}</span>
          </Item>
        </li>
      )
    )}
  </ul>
);

export default ItemLink;
