/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import { toast } from "react-toastify";
import "../../login.css";
import Loading from "../Common/Loading/Loading";
import SnackBar, { tostConfig } from "../Common/SnackBar/SnackBar";
import { setAuthToken } from "../../utils/authToken";
import https from "../../utils/https";
import { Link } from "react-router-dom";
const Login = () => {
  const [user, setUser] = React.useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (user.email && user.password && !isLoading) {
        setIsLoading(true);
        const response = await https.post("/accounts/login", user);
        if (response.data) {
          const data = response.data.user.user_permissions.map(
            (el) => `${el.name}.${el.codename}`
          );
          setAuthToken({
            id: response.data.user.id,
            token: response.data.token,
            permissions: data,
          });
          setIsLoading(false);
          window.location = "/";
        }
      } else {
        setIsLoading(false);
        toast.error("Veuillez remplir tous les champs, s'il vous plait !!", {
          ...tostConfig,
          bodyStyle: { color: "red", textAlign: "center" },
        });
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(
        "Veuillez verifier votre mot de passe ou adresse email, s'il vous plait",
        { ...tostConfig, bodyStyle: { color: "red", textAlign: "center" } }
      );
    }
  };
  return (
    <div className="body-login">
      <div className="center">
        <h1>Connexion</h1>
        <form id="contact-form" onSubmit={handleLogin}>
          <div className="txt_field form-group">
            <input
              type="email"
              value={user.email}
              className="form-control"
              name="email"
              onChange={handleChange}
              required
              id="emaiField"
            />
            <span></span>
            <label>Email d’utilisateur</label>
            <div className="invalid-feedback"></div>
          </div>
          <div className="txt_field form-group">
            <input
              type="password"
              value={user.password}
              name="password"
              onChange={handleChange}
              className="form-control"
              required
              id="passwordField"
            />
            <span></span>
            <label>Mot de passe</label>
            <div className="invalid-feedback"></div>
          </div>
          <div className="pass">
            <Link to="/forgot-password">Mot de passe oublié?</Link>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <button type="submit" className="btn btn-primary">
              Se connecter
            </button>
          )}
          <div className="signup_link"></div>
        </form>
      </div>
      <SnackBar />
    </div>
  );
};

export default Login;
