import * as React from 'react';

const ErrorPage = () => {
    return (
        <div className='pageError'>
            <div className='page-error'>

                <h4 className='mb-4'>Oh nooon..</h4>
                <h6 className='mb-2'>Il semblerait qu'il y ait un problème</h6>
                <p>La page que vous essayez d'atteindre est introuvable</p>
                <a className='btnError' href='/'>Retour à l'accueil</a>

            </div>

        </div>
    );
}

export default ErrorPage 