import React, { Suspense } from 'react';
import Loader from './Loader/Loader';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

const LazyBoundary = ({ children, isPrintError, size }) => (
    <ErrorBoundary isPrintError={isPrintError}>
        <Suspense fallback={<Loader size={size} />}>
            {children}
        </Suspense>
    </ErrorBoundary>
);

export default LazyBoundary