/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ItemLink from "./ItemLink/ItemLink";
import OutsideClickHandler from "react-outside-click-handler";

const SideBar = ({ isContentVisible, toggleContent, handleHide }) => {
  return (
    <section id="sidebar">
      <div className="brand">
        <i className="bx bxs-smile"></i>
        <span className="text" onClick={() => toggleContent()}>
          BabiMams
        </span>

        <button
          className="ml-2 d-block d-md-none"
          onClick={() => toggleContent()}
        >
          <i className="bi bi-list "></i>
        </button>
      </div>
      <div className="d-none d-md-block">
        <ItemLink />
      </div>
      <OutsideClickHandler onOutsideClick={handleHide}>
        <div className={isContentVisible ? "visible" : "hidden"}>
          <ItemLink toggleContent={handleHide} />
        </div>
      </OutsideClickHandler>
    </section>
  );
};

export default SideBar;
