import * as React from "react";
import { useParams } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { toast } from "react-toastify";
import SnackBar, { tostConfig } from "../Common/SnackBar/SnackBar";
import https from "../../utils/https";
import GoBack from "../Common/GoBack/GoBack";
import { setAuthToken } from "../../utils/authToken";

const ResetPwd = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const params = useParams();

  const [user, setUser] = React.useState({
    confirm_password: "",
    new_password: "",
  });

  const handleError = (message) => {
    setIsLoading(false);
    toast.error(message, {
      ...tostConfig,
      bodyStyle: { color: "red", textAlign: "center" },
    });
  };

  const handleCheckedUser = () => {
    if (user.confirm_password !== user.new_password) {
      handleError(
        "Veuillez vérifier la confirmation votre mot de passe, s'il vous plait !!"
      );
    } else {
      https
        .post(`/accounts/reset-password/${params.token}`, {
          ...user,
          email: params.email,
        })
        .then((response) => {
          if (response.data) {
            const data = response.data.user.user_permissions.map(
              (el) => `${el.name}.${el.codename}`
            );
            setAuthToken({
              id: response.data.user.id,
              token: response.data.token,
              permissions: data,
            });
            setIsLoading(false);
            window.location = "/";
          }
        })
        .catch(() => {
          handleError(
            "Une erreur est produite, alors veuillez réessayer, s'il vous plait !!"
          );
        });
    }
  };

  const handleChangePasswords = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleCheckedUser();
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="body-grid">
      <div className="container c_body-grid">
        <div className="col-md-6 mx-auto grid-forgotPawd">
          <h1>Nouveau mot de passer</h1>
          <p>Veuillez entrer ci-après votre nouveau mot de passe.</p>
          <form onSubmit={handleChangePasswords}>
            <input
              required
              type="password"
              name="new_password"
              value={user.new_password}
              placeholder="Entrer votre mot de passe..."
              onChange={handleChange}
            />
            <input
              required
              type="password"
              name="confirm_password"
              value={user.confirm_password}
              placeholder="Confirmer votre mot de passe..."
              onChange={handleChange}
            />
            <br />
            <div className="footer_btn">
              <GoBack />
              {isLoading ? (
                <Loading />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Modifier
                </button>
              )}
            </div>
          </form>
        </div>
        <SnackBar />
      </div>
    </div>
  );
};

export default ResetPwd;
