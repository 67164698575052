import React from "react";
import {
  PopoverBody,
  PopoverHeader,
  Popover,
} from "reactstrap";

const ContextMenu = ({
  header = null,
  children,
  placement = "bottom",
  trigger = "legacy",
  target,
  isOpen,
  togglePopover
}) => {
  return (
    <Popover
      trigger={trigger}
      placement={placement}
      target={target}
      isOpen={isOpen}
      toggle={togglePopover}
    >
      {header && <PopoverHeader>{header}</PopoverHeader>}
      <PopoverBody>{children}</PopoverBody>
    </Popover>
  );
};

export default ContextMenu;
