function capitalizeFirstLetter(str) {
  if (str && typeof str === "string") {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return "";
}

function isArrayJSON(jsonData) {
  try {
    const parsedData = JSON.parse(jsonData);
    return Array.isArray(parsedData);
  } catch (error) {
    return false; // Invalid JSON format
  }
}

const isEmailValid = (email) => {
  const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
  return email !== undefined && regexEmail.test(email.trim());
};

const isValidPassword = (password) => {
  return /^(?=.*?).{4,}$/.test(password);
};

const isValidPhoneNumber = (phonenumber) => {
  return /\+?([0-9]{7,})$/.test(phonenumber);
};

export {
  capitalizeFirstLetter,
  isEmailValid,
  isValidPassword,
  isValidPhoneNumber,
  isArrayJSON,
};

export var tableauSemaines = (length = 41, anotherLabel = "") =>
  Array.from({ length }, function (_, index) {
    return {
      label: `${anotherLabel}${index + 1}`,
      value: `${index + 1}`,
    };
  });
