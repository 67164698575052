import * as React from "react";
import GoBack from "../Common/GoBack/GoBack";

const ConfirmPwd = () => {
  return (
    <div className="body-grid">
      <div className="container c_body-grid">
        <div className="col-md-6 mx-auto grid-forgotPawd">
          <h1>Validation de nouveau mot de passe</h1>
          <p>
            Afin de valider le nouveau mot de passe, veuillez consulter votre
            boîte email et suivre les instructions à l'interieur.
          </p>
          <div className="footer_btn">
            <GoBack />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPwd;
