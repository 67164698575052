import * as React from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Common/Loading/Loading";
import { toast } from "react-toastify";
import SnackBar, { tostConfig } from "../Common/SnackBar/SnackBar";
import https from "../../utils/https";
import GoBack from "../Common/GoBack/GoBack";

const ForgotPwd = () => {
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);

  const [user, setUser] = React.useState({
    email: "",
  });

  const handleError = (message) => {
    setIsLoading(false);
    toast.error(message, {
      ...tostConfig,
      bodyStyle: { color: "red", textAlign: "center" },
    });
  };

  const handleCheckedUser = () => {
    https
      .post("/accounts/forgot-password", {
        email: user.email,
      })
      .then(() => {
        navigation("/confirm-password");
      })
      .catch(() => {
        handleError(
          "Votre compte est introuvable, alors veuillez contacter le support"
        );
      });
  };

  const handleChangePasswords = (e) => {
    e.preventDefault();
    setIsLoading(true);
    handleCheckedUser();
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="body-grid">
      <div className="container c_body-grid">
        <div className="col-md-6 mx-auto grid-forgotPawd">
          <h1>Récupération de compte</h1>
          <p>
            Afin de protéger votre compte et changer votre mot de passe, Babi
            Mams veut s'assurer que c'est bien vous qui essayez de vous
            connecter
          </p>
          <form onSubmit={handleChangePasswords}>
            <input
              required
              type="mail"
              name="email"
              value={user.email}
              placeholder="Entrer votre mail..."
              onChange={handleChange}
            />
            <br />
            <div className="footer_btn">
              <GoBack />
              {isLoading ? (
                <Loading />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Valider
                </button>
              )}
            </div>
          </form>
        </div>
        <SnackBar />
      </div>
    </div>
  );
};

export default ForgotPwd;
