import axios from "axios";
import { getAuthToken } from "./authToken";

const BaseUrl = "https://api-admin.babymams.group";

const token = getAuthToken();
const params = token
  ? {
      id_auth: token ? token.id : "",
    }
  : undefined;
const https = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: token ? token.token : "",
  },
  params:{
    ...params,
    user_type: "admin"
  },
});

export { BaseUrl };
export default https;
