import "./App.css";
import Router from "./router/router";
import moment from 'moment'
import { store } from './store/store'
import { Provider } from 'react-redux'
import 'react-loading-skeleton/dist/skeleton.css'
import 'moment/locale/fr'

moment.locale('fr')

function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}
export default App;
