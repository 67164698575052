export const menuItems = [
  {
    url: "/",
    iconClass: "bx bxs-dashboard",
    text: "Tableau de bord",
  },
  {
    url: "list-customers",
    iconClass: "bx bxs-bar-chart-alt-2",
    text: "Utilisateurs",
    keyItem: "accounts.view_customer_model",
    search: false,
    subItems: [
      {
        url: "list-customers",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: ["accounts.view_customer_model"],
      },
    ],
  },
  {
    url: "appointments",
    iconClass: "bi bi-calendar-date bx",
    text: "Rendez-vous",
    search: true,
    keyItem: "appointment",
    subItems: [
      {
        url: "appointments",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "accounts.view_appointment_model",
          "accounts.delete_appointment_model",
          "accounts.change_appointment_model",
        ],
      },
    ],
  },
  {
    url: "list-ads",
    iconClass: "bi bi-megaphone bx",
    text: "Publicités",
    search: true,
    keyItem: "ads",
    subItems: [
      {
        url: "add-ads",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["ads.create_ads_model"],
      },
      {
        url: "list-ads",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "ads.delete_ads_model",
          "ads.change_ads_model",
          "ads.view_ads_model",
        ],
      },
    ],
  },
  {
    url: "list-belly",
    iconClass: "bi bi-gender-female bx",
    text: "Ventre de Maman",
    search: true,
    keyItem: "belly",
    subItems: [
      {
        url: "add-belly",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["belly.create_belly_model"],
      },
      {
        url: "list-belly",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "belly.delete_belly_model",
          "belly.change_belly_model",
          "belly.view_belly_model",
        ],
      },
    ],
  },
  {
    url: "list-ultrasound",
    iconClass: "bi bi-upc-scan bx",
    text: "Échographie",
    search: true,
    keyItem: "ultrasound",
    subItems: [
      {
        url: "add-ultrasound",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["ultrasound.create_ultrasound_model"],
      },
      {
        url: "list-ultrasound",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "ultrasound.delete_ultrasound_model",
          "ultrasound.change_ultrasound_model",
          "ultrasound.view_ultrasound_model",
        ],
      },
    ],
  },
  {
    url: "list-pregnancies",
    iconClass: "bi bi-person-video2 bx",
    text: "Vidéo d'évolution de Bébé",
    search: true,
    keyItem: "pregnancies",
    subItems: [
      {
        url: "add-pregnancies",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["pregnancies.create_pregnancies_model"],
      },
      {
        url: "list-pregnancies",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "pregnancies.delete_pregnancies_model",
          "pregnancies.change_pregnancies_model",
          "pregnancies.view_pregnancies_model",
        ],
      },
    ],
  },
  {
    url: "list-guide",
    iconClass: "bi bi-badge-ar bx",
    text: "Guides",
    search: true,
    keyItem: "guide",
    subItems: [
      {
        url: "add-guide",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["guide.create_guide_model"],
      },
      {
        url: "list-guide",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "guide.delete_guide_model",
          "guide.change_guide_model",
          "guide.view_guide_model",
        ],
      },
    ],
  },
  {
    url: "list-articles",
    iconClass: "bi bi-badge-ar bx",
    text: "Articles",
    search: true,
    keyItem: "articles",
    subItems: [
      {
        url: "add-articles",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["articles.create_articles_model"],
      },
      {
        url: "list-articles",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "articles.delete_articles_model",
          "articles.change_articles_model",
          "articles.view_articles_model",
        ],
      },
    ],
  },
  {
    url: "list-category-article",
    iconClass: "bi bi-badge-ar bx",
    text: "Categorie article",
    search: true,
    keyItem: "articlesCategory",
    subItems: [
      {
        url: "add-category-article",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["articles.create_articlesCategory_model"],
      },
      {
        url: "list-category-article",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "articles.delete_articlesCategory_model",
          "articles.change_articlesCategory_model",
          "articles.view_articlesCategory_model",
        ],
      },
    ],
  },
  {
    url: "list-course",
    iconClass: "bi bi-bag-plus bx",
    text: "Courses",
    search: true,
    keyItem: "course",
    subItems: [
      {
        url: "add-course",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["course.create_course_model"],
      },
      {
        url: "list-course",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "course.delete_course_model",
          "course.change_course_model",
          "course.view_course_model",
        ],
      },
    ],
  },
  {
    url: "list-category-course",
    iconClass: "bi bi-bag-plus bx",
    text: "Catégorie courses",
    search: true,
    keyItem: "courseCategory",
    subItems: [
      {
        url: "add-category-course",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["course.create_courseCategory_model"],
      },
      {
        url: "list-category-course",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "course.delete_courseCategory_model",
          "course.change_courseCategory_model",
          "course.view_courseCategory_model",
        ],
      },
    ],
  },
  {
    url: "list-faq",
    iconClass: "bi bi-info-circle bx",
    text: "Faqs",
    search: true,
    keyItem: "faqs",
    subItems: [
      {
        url: "add-faq",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["faqs.create_faqs_model"],
      },
      {
        url: "list-faq",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "faqs.delete_faqs_model",
          "faqs.change_faqs_model",
          "faqs.view_faqs_model",
        ],
      },
    ],
  },
  {
    url: "list-vaccines",
    iconClass: "bi bi-virus bx",
    text: "Vaccins",
    search: true,
    keyItem: "vaccine",
    subItems: [
      {
        url: "add-vaccines",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["vaccine.create_vaccine_model"],
      },
      {
        url: "list-vaccines",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "vaccine.delete_vaccine_model",
          "vaccine.change_vaccine_model",
          "vaccine.view_vaccine_model",
        ],
      },
    ],
  },
  {
    url: "list-fruit",
    iconClass: "bi bi-fan bx",
    text: "Fruits",
    search: true,
    keyItem: "fruits",
    subItems: [
      {
        url: "add-fruit",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["fruits.create_fruits_model"],
      },
      {
        url: "list-fruit",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "fruits.delete_fruits_model",
          "fruits.change_fruits_model",
          "fruits.view_fruits_model",
        ],
      },
    ],
  },
  {
    url: "list-country",
    iconClass: "bi bi-pin-map bx",
    text: "Pays",
    search: true,
    keyItem: "adresses",
    subItems: [
      {
        url: "add-country",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["adresses.create_country_model"],
      },
      {
        url: "list-country",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "adresses.delete_country_model",
          "adresses.change_country_model",
          "adresses.view_country_model",
        ],
      },
    ],
  },
  {
    url: "list-city",
    iconClass: "bi bi-pin-map bx",
    text: "Ville",
    search: true,
    keyItem: "adresses",
    subItems: [
      {
        url: "add-city",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["adresses.create_city_model"],
      },
      {
        url: "list-city",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "adresses.delete_city_model",
          "adresses.change_city_model",
          "adresses.view_city_model",
        ],
      },
    ],
  },
  {
    url: "list-good-address",
    iconClass: "bi bi-pin-map bx",
    text: "Bonne adresse",
    search: true,
    keyItem: "adresses",
    subItems: [
      {
        url: "add-good-address",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["adresses.create_adresses_model"],
      },
      {
        url: "list-good-address",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "adresses.delete_adresses_model",
          "adresses.change_adresses_model",
          "adresses.view_adresses_model",
        ],
      },
    ],
  },
  {
    url: "list-category-good-address",
    iconClass: "bi bi-pin-map bx",
    text: "Categorie bonne adresse",
    search: true,
    keyItem: "adressesCategory",
    subItems: [
      {
        url: "add-category-good-address",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["adresses.create_adressesCategory_model"],
      },
      {
        url: "list-category-good-address",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "adresses.delete_adressesCategory_model",
          "adresses.change_adressesCategory_model",
          "adresses.view_adressesCategory_model",
        ],
      },
    ],
  },
  {
    url: "list-names",
    iconClass: "bi bi-universal-access bx",
    text: "Prénom",
    search: true,
    keyItem: "childName",
    subItems: [
      {
        url: "add-names",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["childName.create_childName_model"],
      },
      {
        url: "list-names",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "childName.delete_childName_model",
          "childName.change_childName_model",
          "childName.view_childName_model",
        ],
      },
    ],
  },
  {
    url: "list-category-question",
    iconClass: "bi bi-question-circle bx",
    text: "Categorie question",
    search: true,
    keyItem: "questionsCategory",
    subItems: [
      {
        url: "add-category-question",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["questions.create_questionsCategory_model"],
      },
      {
        url: "list-category-question",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "questions.delete_questionsCategory_model",
          "questions.change_questionsCategory_model",
          "questions.view_questionsCategory_model",
        ],
      },
    ],
  },
  {
    url: "list-questions",
    iconClass: "bi bi-stickies bx",
    text: "Questions",
    search: true,
    keyItem: "questions",
    subItems: [
      {
        url: "add-questions",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["questions.create_questionsCategory_model"],
      },
      {
        url: "list-questions",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "questions.delete_questions_model",
          "questions.change_questions_model",
          "questions.view_questions_model",
        ],
      },
    ],
  },
  {
    url: "list-tasks",
    iconClass: "bi bi-file-text bx",
    text: "Tâches",
    search: true,
    keyItem: "task",
    subItems: [
      {
        url: "add-tasks",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["accounts.create_task_model"],
      },
      {
        url: "list-tasks",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        search: false,
        keyItem: [
          "accounts.delete_task_model",
          "accounts.change_task_model",
          "accounts.view_task_model",
        ],
      },
    ],
  },
  {
    url: "list-users",
    iconClass: "bi bi-person-plus bx",
    text: "Administrateurs",
    search: true,
    keyItem: "user",
    subItems: [
      {
        url: "add-users",
        iconClass: "bx bxs-add-to-queue",
        text: "Ajouter",
        search: false,
        keyItem: ["accounts.create_user_model"],
      },
      {
        url: "list-users",
        iconClass: "bx bxs-bar-chart-alt-2",
        text: "list",
        keyItem: [
          "accounts.delete_user_model",
          "accounts.change_user_model",
          "accounts.view_user_model",
        ],
      },
    ],
  },
];
