export const setAuthToken = (token) => {
  sessionStorage.setItem("token-baby", JSON.stringify(token));
};

export const getAuthToken = () => {
  return JSON.parse(sessionStorage.getItem("token-baby"));
};

export const removeAuthToken = () => {
  sessionStorage.removeItem("token-baby");
};
