import * as React from "react";
import { NavLink } from "react-router-dom";

const Item = ({ url, children }) => {
  let activeStyle = {
    textDecoration: "underline",
  };
  return (
    <NavLink
      to={url}
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
    >
      {" "}
      {children}
    </NavLink>
  );
};

export default Item;
