import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";
import Layout from "../components/Layout/Layout";
import LazyBoundary from "../components/LazyBoundary/LazyBoundary";
import Login from "../components/Login/Login";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import NotFound from "../components/NotFound/NotFound";
import ForgotPwd from "../components/ForgotPwd/ForgotPwd";
import ConfirmPwd from "../components/ConfirmPwd/ConfirmPwd";
import ResetPwd from "../components/ResetPwd/ResetPwd";

const ListBelly = lazy(() => import("../components/Belly/ListBelly/ListBelly"));
const AddBelly = lazy(() => import("../components/Belly/AddBelly/AddBelly"));

const ListCountry = lazy(() =>
  import("../components/Country/ListCountry/ListCountry")
);
const AddCountry = lazy(() =>
  import("../components/Country/AddCountry/AddCountry")
);

const ListCity = lazy(() => import("../components/City/ListCity/ListCity"));
const AddCity = lazy(() => import("../components/City/AddCity/AddCity"));

const ListGuide = lazy(() => import("../components/Guide/ListGuide/ListGuide"));
const AddGuide = lazy(() => import("../components/Guide/AddGuide/AddGuide"));

const AddCourse = lazy(() =>
  import("../components/Course/AddCourse/AddCourse")
);
const AddCategoryCourse = lazy(() =>
  import("../components/CategoryCourse/AddCategoryCourse/AddCategoryCourse")
);

const ListCategoryCourse = lazy(() =>
  import("../components/CategoryCourse/ListCategoryCourse/ListCategoryCourse")
);
const ListCourse = lazy(() =>
  import("../components/Course/ListCourse/ListCourse")
);

const ListUltrasound = lazy(() =>
  import("../components/Ultrasound/ListUltrasound/ListUltrasound")
);
const AddUltrasound = lazy(() =>
  import("../components/Ultrasound/AddUltrasound/AddUltrasound")
);

const ListCategoryQuestion = lazy(() =>
  import(
    "../components/CategoryQuestion/ListCategoryQuestion/ListCategoryQuestion"
  )
);
const AddCategoryQuestion = lazy(() =>
  import(
    "../components/CategoryQuestion/AddCategoryQuestion/AddCategoryQuestion"
  )
);

const ListAds = lazy(() => import("../components/Ads/ListAds/ListAds"));
const AddAds = lazy(() => import("../components/Ads/AddAds/AddAds"));

const ListPrenancyEvolution = lazy(() =>
  import(
    "../components/PrenancyEvolution/ListPrenancyEvolution/ListPrenancyEvolution"
  )
);
const AddPrenancyEvolution = lazy(() =>
  import(
    "../components/PrenancyEvolution/AddPrenancyEvolution/AddPrenancyEvolution"
  )
);

const ListCategoryArticle = lazy(() =>
  import(
    "../components/CategoryArticle/ListCategoryArticle/ListCategoryArticle"
  )
);
const AddCategoryArticle = lazy(() =>
  import("../components/CategoryArticle/AddCategoryArticle/AddCategoryArticle")
);

const Home = lazy(() => import("../components/Home/Home"));
const ListAppointments = lazy(() =>
  import("../components/Appointments/ListAppointments/ListAppointments")
);
const AddArticles = lazy(() =>
  import("../components/Articles/AddArticles/AddArticles")
);
const ListArticles = lazy(() =>
  import("../components/Articles/ListArticles/ListArticles")
);
const AddFaqs = lazy(() => import("../components/Faqs/AddFaqs/AddFaqs"));
const ListFaqs = lazy(() => import("../components/Faqs/ListFaqs/ListFaqs"));
const AddFruit = lazy(() => import("../components/Fruit/AddFruit/AddFruit"));
const ListFruit = lazy(() => import("../components/Fruit/ListFruit/ListFruit"));
const AddGoodAddress = lazy(() =>
  import("../components/GoodAddress/AddGoodAddress/AddGoodAddress")
);
const ListGoodAddress = lazy(() =>
  import("../components/GoodAddress/ListGoodAddress/ListGoodAddress")
);
const AddVaccines = lazy(() =>
  import("../components/Vaccines/AddVaccines/AddVaccines")
);

const ListVaccines = lazy(() =>
  import("../components/Vaccines/ListVaccines/ListVaccines")
);

const AddNames = lazy(() => import("../components/Names/AddNames/AddNames"));
const ListNames = lazy(() => import("../components/Names/ListNames/ListNames"));
const AddQuestions = lazy(() =>
  import("../components/Questions/AddQuestions/AddQuestions")
);
const ListQuestions = lazy(() =>
  import("../components/Questions/ListQuestions/ListQuestions")
);
const AddTasks = lazy(() => import("../components/Tasks/AddTasks/AddTasks"));
const ListTasks = lazy(() => import("../components/Tasks/ListTasks/ListTasks"));
const AddUsers = lazy(() => import("../components/Users/AddUsers/AddUsers"));
const ListUsers = lazy(() => import("../components/Users/ListUsers/ListUsers"));

const AddCategoryAddress = lazy(() =>
  import("../components/CategoryAddress/AddCategoryAddress/AddCategoryAddress")
);
const ListCategoryAddress = lazy(() =>
  import(
    "../components/CategoryAddress/ListCategoryAddress/ListCategoryAddress"
  )
);

const ListCustomers = lazy(() =>
  import("../components/Users/ListCustomers/ListCustomers")
);

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute isRouteInit={true}>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route
            index
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute isRouteInit={true}>
                  <Home />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/appointments"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "accounts.delete_appointment_model",
                    "accounts.view_appointment_model",
                    "accounts.change_appointment_model",
                  ]}
                >
                  <ListAppointments />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-articles"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["articles.create_articles_model"]}
                >
                  <AddArticles />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-guide"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["guide.create_guide_model"]}>
                  <AddGuide />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-course"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["course.create_course_model"]}>
                  <AddCourse />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-articles/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["articles.change_articles_model"]}
                >
                  <AddArticles />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-course/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["course.change_course_model"]}>
                  <AddCourse />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-guide/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["guide.change_guide_model"]}>
                  <AddGuide />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-category-article"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["articles.create_articlesCategory_model"]}
                >
                  <AddCategoryArticle />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-category-course"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["course.create_courseCategory_model"]}
                >
                  <AddCategoryCourse />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-category-article/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["articles.change_articlesCategory_model"]}
                >
                  <AddCategoryArticle />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-category-course/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["course.change_courseCategory_model"]}
                >
                  <AddCategoryCourse />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-category-article"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "articles.delete_articlesCategory_model",
                    "articles.view_articlesCategory_model",
                    "articles.change_articlesCategory_model",
                  ]}
                >
                  <ListCategoryArticle />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-category-course"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "course.delete_courseCategory_model",
                    "course.view_courseCategory_model",
                    "course.change_courseCategory_model",
                  ]}
                >
                  <ListCategoryCourse />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-category-question"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["questions.create_questionsCategory_model"]}
                >
                  <AddCategoryQuestion />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-category-question/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["questions.change_questionsCategory_model"]}
                >
                  <AddCategoryQuestion />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-category-question"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "questions.delete_questionsCategory_model",
                    "questions.view_questionsCategory_model",
                    "questions.change_questionsCategory_model",
                  ]}
                >
                  <ListCategoryQuestion />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-faq/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["faqs.change_faqs_model"]}>
                  <AddFaqs />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-articles"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "articles.delete_articles_model",
                    "articles.view_articles_model",
                    "articles.change_articles_model",
                  ]}
                >
                  <ListArticles />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-guide"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "guide.delete_guide_model",
                    "guide.view_guide_model",
                    "guide.change_guide_model",
                  ]}
                >
                  <ListGuide />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-course"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "course.delete_course_model",
                    "course.view_course_model",
                    "course.change_course_model",
                  ]}
                >
                  <ListCourse />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-faq"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["faqs.create_faqs_model"]}>
                  <AddFaqs />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-faq"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "faqs.delete_faqs_model",
                    "faqs.view_faqs_model",
                    "faqs.change_faqs_model",
                  ]}
                >
                  <ListFaqs />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-vaccines"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["vaccine.create_vaccine_model"]}
                >
                  <AddVaccines />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-vaccines/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["vaccine.change_vaccine_model"]}
                >
                  <AddVaccines />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-vaccines"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "vaccine.delete_vaccine_model",
                    "vaccine.view_vaccine_model",
                    "vaccine.change_vaccine_model",
                  ]}
                >
                  <ListVaccines />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-fruit"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["fruits.create_fruits_model"]}>
                  <AddFruit />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-fruit/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["fruits.change_fruits_model"]}>
                  <AddFruit />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-fruit"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "fruits.delete_fruits_model",
                    "fruits.view_fruits_model",
                    "fruits.change_fruits_model",
                  ]}
                >
                  <ListFruit />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/add-ads"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["ads.create_ads_model"]}>
                  <AddAds />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/add-city"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["adresses.create_city_model"]}>
                  <AddCity />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/add-country"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["adresses.create_country_model"]}
                >
                  <AddCountry />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-belly"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["belly.create_belly_model"]}>
                  <AddBelly />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-ultrasound"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["ultrasound.create_ultrasound_model"]}
                >
                  <AddUltrasound />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-ads/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["ads.change_ads_model"]}>
                  <AddAds />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/modify-city/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["adresses.change_city_model"]}>
                  <AddCity />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/modify-country/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["adresses.change_country_model"]}
                >
                  <AddCountry />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/modify-ultrasound/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["ultrasound.change_ultrasound_model"]}
                >
                  <AddUltrasound />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-belly/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["belly.change_belly_model"]}>
                  <AddBelly />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-ads"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "ads.delete_ads_model",
                    "ads.view_ads_model",
                    "ads.change_ads_model",
                  ]}
                >
                  <ListAds />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/list-city"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "adresses.delete_city_model",
                    "adresses.view_city_model",
                    "adresses.change_city_model",
                  ]}
                >
                  <ListCity />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/list-country"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "adresses.delete_country_model",
                    "adresses.view_country_model",
                    "adresses.change_country_model",
                  ]}
                >
                  <ListCountry />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-belly"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "belly.delete_belly_model",
                    "belly.view_belly_model",
                    "belly.change_belly_model",
                  ]}
                >
                  <ListBelly />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-ultrasound"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "ultrasound.delete_ultrasound_model",
                    "ultrasound.view_ultrasound_model",
                    "ultrasound.change_ultrasound_model",
                  ]}
                >
                  <ListUltrasound />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-pregnancies"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["pregnancies.create_pregnancies_model"]}
                >
                  <AddPrenancyEvolution />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-pregnancies/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["pregnancies.change_pregnancies_model"]}
                >
                  <AddPrenancyEvolution />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-pregnancies"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "pregnancies.delete_pregnancies_model",
                    "pregnancies.view_pregnancies_model",
                    "pregnancies.change_pregnancies_model",
                  ]}
                >
                  <ListPrenancyEvolution />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-good-address"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["adresses.create_adresses_model"]}
                >
                  <AddGoodAddress />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-good-address/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["adresses.change_adresses_model"]}
                >
                  <AddGoodAddress />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-good-address"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "adresses.delete_adresses_model",
                    "adresses.view_adresses_model",
                    "adresses.change_adresses_model",
                  ]}
                >
                  <ListGoodAddress />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/add-category-good-address"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["adresses.create_adressesCategory_model"]}
                >
                  <AddCategoryAddress />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-category-good-address/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["adresses.change_adressesCategory_model"]}
                >
                  <AddCategoryAddress />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-category-good-address"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "adresses.delete_adressesCategory_model",
                    "adresses.view_adressesCategory_model",
                    "adresses.change_adressesCategory_model",
                  ]}
                >
                  <ListCategoryAddress />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-names"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["childName.create_childName_model"]}
                >
                  <AddNames />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-names/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["childName.change_childName_model"]}
                >
                  <AddNames />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-names"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "childName.delete_childName_model",
                    "childName.view_childName_model",
                    "childName.change_childName_model",
                  ]}
                >
                  <ListNames />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-questions"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["questions.create_questions_model"]}
                >
                  <AddQuestions />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-questions/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={["questions.change_questions_model"]}
                >
                  <AddQuestions />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-questions"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "questions.delete_questions_model",
                    "questions.view_questions_model",
                    "questions.change_questions_model",
                  ]}
                >
                  <ListQuestions />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-tasks"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["accounts.create_task_model"]}>
                  <AddTasks />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-tasks/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["accounts.change_task_model"]}>
                  <AddTasks />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />

          <Route
            path="/list-tasks"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "accounts.delete_task_model",
                    "accounts.view_task_model",
                    "accounts.change_task_model",
                  ]}
                >
                  <ListTasks />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/add-users"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["accounts.create_user_model"]}>
                  <AddUsers />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/modify-users/:id"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute permissionKey={["accounts.change_user_model"]}>
                  <AddUsers />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-users"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "accounts.delete_user_model",
                    "accounts.view_user_model",
                    "accounts.change_user_model",
                  ]}
                >
                  <ListUsers />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
          <Route
            path="/list-customers"
            element={
              <LazyBoundary isPrintError={true} size={30}>
                <ProtectedRoute
                  permissionKey={[
                    "accounts.delete_customer_model",
                    "accounts.view_customer_model",
                    "accounts.change_customer_model",
                  ]}
                  isRouteInit={true}
                >
                  <ListCustomers />
                </ProtectedRoute>
              </LazyBoundary>
            }
          />
        </Route>
        <Route
          path="/login"
          element={
            <ProtectedRoute isPublic={true}>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <ProtectedRoute isPublic={true}>
              <ForgotPwd />
            </ProtectedRoute>
          }
        />
        <Route
          path="/confirm-password"
          element={
            <ProtectedRoute isPublic={true}>
              <ConfirmPwd />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reset-password/:token/:email"
          element={
            <ProtectedRoute isPublic={true}>
              <ResetPwd />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
